.landing-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  position: fixed;
  background-color: rgb(20,20,20);
  color: white;
  z-index: 1000;
  border-bottom: 1px solid #28009e;
}

.landing-menu-container {
  display: none;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.landing-menu-item {
  min-width: 100px;
  margin-right: 15px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent; /* Initially transparent border */
  transition: border-bottom-color 0.3s ease; /* Smooth transition effect */
}

.landing-mobile-menu-container {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.landing-mobile-menu-burger-container {
  display: flex;
  width: 100px;
  height: 75px;
  justify-content: space-around;
  align-items: center;
  flex-flow: column;
  padding-top: 25px;
  padding-bottom: 25px;
  cursor: pointer;
}

.landing-mobile-menu-burger-slice {
  display: flex;
  width: 45px;
  height: 3px;
  background-color: white;
  border-radius: 3px;
}

.landing-menu-item:hover {
  border-bottom-color: white; /* White border on hover */
}

.landing-mobile-menu {
  position: fixed;
  right: 0%;
  top: 75px;
  width: 50%;
  height: 100%;
}

.landing-mobile-menu-option {
  width: 100%;
  height: 45px;
  background-color: rgb(20,20,20);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

 }

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .landing-menu-container {
    display: flex;
  }

  .landing-mobile-menu-burger-container {
    display: none;
  }

  .landing-mobile-menu {
    display: none;
  }

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
