.builder-modal--container {
 width: 100%;
 height: 125px;
 display: flex;
 justify-content: space-around;
 align-items: center;
}

.builder-modal--preset-container {
 width: 45%;
 height: 100%;
 -webkit-box-shadow: 10px 10px 31px -22px rgba(0,0,0,0.75);
 -moz-box-shadow: 10px 10px 31px -22px rgba(0,0,0,0.75);
 box-shadow: 10px 10px 31px -22px rgba(0,0,0,0.75);
 cursor: pointer;
}

.builder-modal--box {
 width: 100%;
 height: 100%;
 background-size: 150%;
 background-position: center;
 border-top-right-radius: 15px;
 border-top-left-radius: 15px;
}

.empty {
 background-image: url('../../assets/images/blank-preset.JPG');
 background-size: 150%;
 background-position: center;
}

.john-preset {
 background-image: url('../../assets/images/john-preset.JPG');
}

.jessica-preset {
 background-image: url('../../assets/images/jessica-preset.JPG');
}

.sample-preset {
 background-image: url('../../assets/images/sample-preset.JPG');
}

.builder-modal--bottom {
 width: 100%;
 height: 30px;
 border-bottom-left-radius: 15px;
 border-bottom-right-radius: 15px;
 border-top: 1px solid white;
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 transition: .3s;
}

.builder-modal--active {
 background-color: #0d6efd;
}

.builder-modal--inactive {
 background-color: #565e64;
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
 .builder-modal--container {
   height: 200px;
 }
}