.contact--container {
  background-size: cover;
  width: 100vw;
  border-top: 1px solid rgb(36, 36, 36);
}

.contact--box {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  border: 1px solid rgb(36, 36, 36);
  padding: 15px;
  -webkit-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
}

.contact--subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Play', sans-serif;
  margin-bottom: 15px;
  color: white;
  position: relative;
  opacity: 0;
  transform: translateY(25px);
}

.contact--card {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  font-family: 'Play', sans-serif;
  font-size: 24px;
  color: white;
  font-weight: 400;
}

.contact--contact-method {
  margin-top: 15px;
  color: #0398fc;
  cursor: pointer;
  font-size: 16px;
}

.contact--scroll-to-point {
  position: relative;
  bottom: 100px;
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .contact--box {
    padding: 50px;
  }
 }

 /* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

 }