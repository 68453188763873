.preview-header-you-are-currently-mobile {
 width: 100vw;
 min-height: 30px;
 height: auto;
 position: fixed;
 top: 75px;
 background-color: #1A0061;
 text-align: center;
 display: flex;
 justify-content: center;
 color: white;
 z-index: 1000;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 .preview-header-you-are-currently-mobile {
  display: none;
 }
}