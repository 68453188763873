.applications--header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.applications--color-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  position: absolute;
  z-index: 502;
  top: 199px;
  right: 25px;
  animation: color-fade .5s forwards;
}

.hide-rearrange-arrows-on-mobile {
  display: flex;
}

.show-rearrange-buttons-on-mobile {
  display: none !important;
}

.move-application-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* For screens smaller than 1200px */
@media (max-width: 1199px) {

  .hide-rearrange-arrows-on-mobile {
    display: none !important;
  }

  .show-rearrange-buttons-on-mobile {
    display: flex !important;
  }
 }