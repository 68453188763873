.light-mode-header-container {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 75px;
 position: fixed;
 color: black;
 z-index: 1000;
 border-bottom: 1px solid #D2D2D2;
 background-color: #F9F9F9;
}

.light-mode-mobile-menu-burger-slice {
 display: flex;
 width: 45px;
 height: 3px;
 background-color: grey;
 border-radius: 3px;
}

.light-mode-mobile-menu-option {
 width: 100%;
 height: 45px;
 background-color: #F9F9F9;
 display: flex;
 justify-content: center;
 align-items: center;
 color: black;
}