.demo-skill-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(108,117,125);
  height: 100px;
}

.skills--blocks {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}



.skills--skill-avatar {
  border-radius: 50%;
  border: 2px solid white;
  width: 100px;
  height: 100px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  -webkit-box-shadow: 9px 9px 20px -16px white;
  -moz-box-shadow: 9px 9px 20px -16px white;
  box-shadow: 9px 9px 20px -16px white;
}

.skills--skill-icon {
  width: 100px;
  height: 100px;
}

.skills--demo-skill-img {
  width: 200px;
  height: 200px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid white;
  border-radius: 50%;
  margin: auto;
  -webkit-box-shadow: 10px 10px 28px -18px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 28px -18px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 28px -18px rgba(0,0,0,0.75);
}

.demo-tech-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(249,249,249);
  height: 100px;
}

.skills--scroll-to-point {
  position: relative;
  bottom: 100px;
}

.add-tech-color-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  position: absolute;
  z-index: 500;
  top: 388px;
  right: 200px;
  animation: color-fade .5s forwards;
}

.add-tech-color-container-hide {
  display: none;
}

.add-label-color-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  position: absolute;
  z-index: 500;
  top: 460px;
  right: 200px;
  animation: color-fade .5s forwards;
}

.add-label-color-container-hide {
  display: none;
}



/* Media queries for responsive design */

 /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) { 

  .skills--skill-avatar {
    width: 150px;
    height: 150px;
  }
  .skills--skill-img {
    width: 150px;
    height: 150px;
    background-size: 100px 100px;
  }
}