.applications--container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  border-top: 1px solid rgb(36, 36, 36);
}

.applications--desc {
  width: 100%;
}

.applications--box {
  width: 100%;
  height: auto;
}

.applications--desc-container {
  width: 100%;
  padding: 15px;
  background: rgb(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.applications--inner-box {
  width: 100%;
  min-height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  border: rgb(36, 36, 36) 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  font-size: 36px;
  color: white;
  font-family: "Play";
  transition: .2s;
  padding: 50px 0 50px 0;
  margin-top: 30px;
    -webkit-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
}

.applications--logo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid white;
  background-position: center;
  -webkit-box-shadow: 9px 9px 20px -16px white;
  -moz-box-shadow: 9px 9px 20px -16px white;
  box-shadow: 9px 9px 20px -16px white;
  margin-bottom: 7px;
}

.applications--button-container {
  width: 100%;
  height: 45px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applications--screenshots-button {
  width: 150px;
  height: 45px;
  background-color: rgb(44, 44, 44);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-family: 'Play', sans-serif;
  margin-right: 15px;
  cursor: pointer;
  text-align: center;
  -webkit-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  transition: .2s;
}

.applications--screenshots-button:hover {
  background-color: rgb(71, 71, 71);
  transition-property: .2s;
}

.applications--demo-button {
  width: 150px;
  height: 45px;
  background-color: #2247D2;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-family: 'Play', sans-serif;
  cursor: pointer;
  text-align: center;
  -webkit-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  box-shadow: 9px 9px 20px -16px rgba(59, 48, 48, 0.75);
  transition: .2s;
}

.applications--demo-button:hover {
  background-color: rgb(53, 95, 248);
  transition: .2s;
}

.company-1-logo {
  background-size: cover;
}

.company-2-logo {
  background-size: cover;
}

.company-3-logo {
  background-size: cover;
}

.applications--scroll-to-point {
  position: relative;
  bottom: 100px;
}

.donut-tech-logo {
  background-image: url('../../assets/images/donut-tech-logo.png');
}

.coffee-connect-logo {
  background-image: url('../../assets/images/coffee-connect-logo.png');
}

.stellar-dynamics-logo {
  background-image: url('../../assets/images/stellar-dynamics-logo.png');
}

.nebula-aerospace-logo {
  background-image: url('../../assets/images/nebula-aerospace-logo.png');
}

.donut {
  background-image: url('../../assets/images/donut.png');
}

.coffee {
  background-image: url('../../assets/images/coffee.png');
}

.green-dots {
  background-image: url('../../assets/images/green-dots.png');
}

.purple-dots {
  background-image: url('../../assets/images/purple-dots.png');
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .applications--desc-container {
    padding: 15px 50px 15px 50px;
  }
 }