.landing-row {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-desc-container {
  text-align: left;
  width: 75%;
  height: auto;
  color: white;
  padding-left: 15px;
  position: absolute;
  top: 75%;
}

.landing-header-row {
  position: fixed;
  width: 100vw;
}


.landing-middle-column {
  height: 100vh;
  background: linear-gradient(#23008F, #1A0061);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
}

.shape-background {
  position: absolute;
  width: inherit;
  height: 100%;
  background-size: cover;
}

.landing-desc-bar {
  height: 5px;
  margin: 15px 0px 15px 0px;
  background: linear-gradient(to right, white, transparent);
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .landing-desc-container {
    width: 75%;
  }
 }

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 
  .landing-desc-container {
    width: 75%;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .landing-desc-container {
    width: 35%;
  }
 
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .landing-desc-container {
    width: 35%;
  }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .landing-desc-container {
    width: 35%;
  }

}
