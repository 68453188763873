.rearrange-container {
  width: 100%;
  margin: 15px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rearrange-block {
  width: 90%;
  height: 40px;
  margin-top: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rearrange-left {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: .5s;
  cursor: pointer;
  border: 1px solid #808080;
}

.rearrange-left:hover {
  background-color: rgb(20,20,20);
}

.rearrange-right {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: .5s;
  cursor: pointer;
  border: 1px solid #808080;
}

.rearrange-right:hover {
  background-color: rgb(20,20,20);
}

.delete-skill {
  margin-top: 15px;
  cursor: pointer;
}

.rearrange-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Display an ellipsis (...) to indicate truncated text */
}

.add-your-own-skill-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}


.color-collapse-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 56px;
  position: absolute;
  pointer-events: none;
  padding-right: 45px;
}

.color-collapse {
  display: flex;
  border: 2px solid grey;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  pointer-events: auto;
  z-index: 501;
}

.color-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  position: absolute;
  z-index: 502;
  top: 185px;
  right: 25px;
  animation: color-fade .5s forwards;
}

.color-container-hide {
  display: none;
}

.hide-input-container {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hide-input-button {
  position: relative;
  top: -8px;
  right: -5px;
}

@keyframes color-fade {
  0% {opacity: 0%}
  100% {opacity: 100%}
}

@media (max-width: 1199px) {
  .rearrange-left {
    width: 30px;
    padding: 0px;
  }

  .rearrange-right {
    width: 30px;
    padding: 0px;
  }
}