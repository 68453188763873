.about-me--container {
  text-align: left;
}

.about-me--text-box {
  width: 100%;
  height: auto;
  min-height: 150px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  flex-flow: column;
  padding: 15px;
  background-color: rgb(20,20,20, .7);
  border: 1px solid rgb(36, 36, 36);
  -webkit-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
}

.about-me--desc-container {
  padding-top: 50px;
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .about-me--text-box {
    padding: 50px;
  }
 }