.mobile-view-container {
 height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
 color: black;
}

.mobile-view-smile {
 width: 350px;
 height: 250px;
 background-image: url('../../assets/images/builder.png');
 background-size: cover;
 background-position: center;
 font-weight: bold;
 color: #23008F;
 margin-bottom: 30px;
}