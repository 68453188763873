.builder-intro-container {
  background-size: cover;
  width: 100%;
  background-color: #2e2e2e;
}

.builder-intro-header {
  color: white;
  margin: auto;
  padding-top: 75px;
  padding-bottom: 75px;
  font-size: 2.125rem;
}

.builder-intro-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.builder-intro-box {
 width: 100%;
 min-height: 750px;
 height: 100%;
 background-image: none;
 background-color: rgb(0, 0, 78);
 background-attachment: fixed;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border-radius: 15px;
 -webkit-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
 -moz-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
 box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
 padding: 15px;
}

.builder-intro-horizon-text {
  font-family: 'Play', sans-serif;
  font-size: 64px;
  margin-top: 50px;
}

.builder-intro-white-bar {
  width: 200px;
  height: 15px;
  background: linear-gradient(to right, transparent, white);
}

.builder-intro-preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  border: 1px solid white;
  background-color: rgb(20,20,20, .7);
  padding: 15px;
}

.builder-intro-text-container {
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
  color: white;
}

.builder-intro-avatar-1 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: white;
  background-image: url('../../assets/images/john.JPG');
  background-size: cover;
  background-position: center;
  border: 5px solid white;
}

.builder-intro-avatar-2 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: white;
  background-image: url('../../assets/images/jessica.JPG');
  background-size: cover;
  background-position: center;
  border: 5px solid white;
}

.builder-intro-template-desc {
  color: white;
  width: 100%;
  height: auto;
  padding: 30px 0 30px 0;
  background: linear-gradient(to right, transparent, rgb(20,20,20, .5), transparent);
}

.builder-intro-text-title {
  border-bottom: 1px solid white;
  font-size: 20px;
  margin: 15px 0 15px 0;
}

.builder-intro-text-desc {
  margin-top: 7px;
}

.builder--intro-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.builder-intro-start-building-container {
  margin: 30px 0 30px 0;
  display: flex;
}

.builder-intro-jump-to-point {
  position: relative;
  bottom: 75px;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

 }

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 
  .builder-intro-white-bar {
    height: 5px;
  }

  .builder-intro-text-container {
    width: 75%;
  }

  .builder-intro-template-desc {
    width: 75%;
  }

  .builder-intro-box {
    padding: 0px;
    background-image: url('../../assets/images/john-background-1.png');
  }

  .builder-intro-preview-container {
    width: 75%;
  }
 }

 /* Medium devices */
@media (min-width: 768px) {

  .builder-intro-white-bar {
    height: 15px;
  }

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .builder-intro-white-bar {
    height: 5px;
  }
  .builder-intro-preview-container {
    width: 75%;
    min-height: 200px;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: row;
    border: 1px solid white;
    background-color: rgb(20,20,20, .7);
  }

  .builder-intro-text-container {
    width: 50%;
    min-height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: column;
    color: white;
  }

  .builder--intro-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .builder-intro-text-title {
    border-bottom: 1px solid white;
    font-size: 20px;
  }

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

 }