.pricing-container {
 width: 100%;
 height: auto;
 background-color: rgb(20,20,20);
}

.pricing-icon-container {
 background-color: #28009e;
 min-width: 200px;
 min-height: 200px;
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 48px;
 font-style: italic;
 border: 3px solid white;
 border-radius: 50%;
 -webkit-box-shadow: 9px 9px 20px -16px white;
 -moz-box-shadow: 9px 9px 20px -16px white;
 box-shadow: 9px 9px 20px -16px white;
}

.pricing-desc-container {
 width: 100%;
 height: 100%;
 color: white;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 flex-flow: column;
}

.pricing-triangle-right-2 {
 width: 0;
 height: 300px;
 border-right: 50px solid transparent;
 border-left: 0px solid transparent;
 border-top: 300px solid rgb(20,20,20);
}

.pricing-square-desc-title {
 font-size: 24px;
 width: 75%;
 text-align: center;
 margin: 15px 0 15px 0;
}

.pricing-square-desc {
 width: 75%;
}

.pricing-title {
 font-size: 36px;
 text-align: center;
 color: white;
 min-height: 150px;
 height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
}

.pricing-button-container {
 width: 100%;
 height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
}

.pricing-square-left-container {
 width: 100%;
 margin: 15px 0 15px 0;
 display: flex;
 justify-content: flex-start;
 flex-flow: column;
 align-items: center;
 padding: 15px;
 border-radius: 15px;
 background-color: #1A0061;
}

.pricing-jump-to-point {
  position: relative;
  bottom: 75px;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 

}

.pricing-mobile {
  display: flex;
}

.pricing-laptop {
  display: none;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
.pricing-mobile {
  display: none;
}

.pricing-laptop {
  display: flex;
}

.pricing-square-left-container {
  height: 100%;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 



}
