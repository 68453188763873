.purchase-horizon-container {
 width: 100%;
 min-height: 1000px;
 background-color: rgb(20,20,20);
}

.purchase-horizon-laptop-container {
 width: 100%;
 min-height: 1000px;
 background: linear-gradient( #28009e, rgb(20,20,20));
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
}

.purchase-horizon-laptop-container-mobile {
 width: 100%;
 min-height: auto;
 background: linear-gradient( rgb(20,20,20), #28009e);
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
 margin-top: 75px;
 padding-bottom: 75px;
}

.purchase-horizon-desc-container {
 width: 100%;
 min-height: 1000px;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
}

.purchase-horizon-desc-container-mobile {
 width: 100%;
 min-height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
 margin-top: 75px;
}

.purchase-laptop-1 {
 transition: .25s;
 width: 100%;
 height: 500px;
 background-image: url('../../assets/images/purchase-laptop.png');
 background-size: contain;
 background-position: center;
 background-repeat: no-repeat;
}

.purchase-horizon-desc-box {
 width: 100%;
 height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
 flex-flow: column;
}

.purchase-horizon-title {
 font-size: 48px;
}

.purchase-horizon-text {
 font-size: 16px;
 margin: 15px 0 15px 0;
}

.purchase-laptop-title {
 color: white;
 font-size: 48px;
 font-family: 'Play', sans-serif;
}

.purchase-white-bar {
 width: 50%;
 height: 5px;
 background: linear-gradient(to right, white, transparent);
}

.purchase-button-container {
 width: 100%;
 height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
}