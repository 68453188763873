.builder-intro-header-container {
 background-size: cover;
 width: 100%;
 height: 75px;
}

.builder-intro-header-middle {
 background-color: #23008F;
 height: 100%;
 color: white;
 display: flex;
 justify-content: space-between;
 align-items: center;
 border-bottom: 1px solid rgb(20,20,20);
}

.builder-intro-header-left-header {
 display: none;
 justify-content: flex-end;
 align-items: center;
}

.builder-intro-header-menu-option {
 margin-left: 30px;
 cursor: pointer;
 display: none;
}

.devhorizon-logo-black {
 background-image: url('../../assets/images/dev-logo-black.png');
 background-size: contain;
 background-position: start;
 background-repeat: no-repeat;
 width: 150px;
 height: 50px;
 opacity: .75;
}

.devhorizon-logo-white {
 background-image: url('../../assets/images/dev-logo.png');
 background-size: contain;
 background-position: start;
 background-repeat: no-repeat;
 width: 150px;
 height: 50px;
}


.builder-intro-mobile-menu {
 position: absolute;
 right: 0%;
 top: 75px;
 width: 50%;
 height: 100%;
 z-index: 1000;
}

.builder-intro-mobile-menu-option {
 width: 100%;
 height: 45px;
 background-color: rgb(20,20,20);
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

 
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 

 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

 .builder-intro-header-left-header {
  display: flex;
 }
 
 .builder-intro-header-menu-option {
  margin-left: 30px;
  cursor: pointer;
  display: flex;
 }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
