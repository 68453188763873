.squares-why-skillsho {
  font-size: 36px;
  text-align: center;
  color: black;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.squares-container {
  width: 100%;
  height: auto;
  background: linear-gradient(rgb(20,20,20), #1A0061)
}

.square-left-container {
  width: 100%;
  height: 300px;
  margin: 15px 0 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.triangle-left {
  width: 0;
  height: 300px;
  border-left: 50px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 300px solid #28009e;
}

.triangle-right {
  width: 0;
  height: 300px;
  border-right: 50px solid transparent;
  border-left: 0px solid transparent;
  border-top: 300px solid #28009e;
  background-color: #190063;
}

.triangle-left-reverse {
  width: 0;
  height: 300px;
  border-left: 50px solid transparent;
  border-right: 0px solid transparent;
  border-top: 300px solid #190063;
}

.triangle-right-reverse {
  width: 0;
  height: 300px;
  border-right: 50px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 300px solid #28009e;
}

.square-icon-container {
  background-color: #28009e;
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square-desc-container {
  background-color: #190063;
  width: 65%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.square-desc-title {
  font-size: 24px;
  width: 75%;
  border-bottom: 1px solid white
}

.square-desc-title-mobile {
  font-size: 24px;
  width: 75%;
  border-bottom: 1px solid white;
  margin-bottom: 15px;
}

.square-desc {
  width: 75%;
}

.triangle-right-2 {
  width: 0;
  height: 300px;
  border-right: 50px solid transparent;
  border-left: 0px solid transparent;
  border-top: 300px solid #190063;
}

.triangle-right-2-reverse {
  width: 0;
  height: 300px;
  border-right: 50px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 300px solid #190063;
  background-color: #28009e;
}

.coding-icon {
  width: 75%;
  height: 75%;
  background-image: url('../../assets/images/code.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}
 
.coding-icon-mobile {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/images/code.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.html-icon {
  width: 75%;
  height: 75%;
  background-image: url('../../assets/images/html.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.html-icon-mobile {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/images/html.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.devices-icon {
  width: 75%;
  height: 75%;
  background-image: url('../../assets/images/devices.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.devices-icon-mobile {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/images/devices.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.document-icon {
  width: 75%;
  height: 75%;
  background-image: url('../../assets/images/clock.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.document-icon-mobile {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/images/clock.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin: auto;
}

.squares-mobile-header {
width: 100%;
height: auto;
color: white;
background-color: #190063;
display: flex;
justify-content: center;
align-items: center;
flex-flow: column;
border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
padding: 15px 0 15px 0;
margin-bottom: 15px;
}

.squares-mobile-image-container {
  width: 100%;
  height: 100px;
  background-color: #28009e;
  border-top-left-radius: 15px;
border-top-right-radius: 15px;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 

}

.squares-mobile {
  display: flex;
  background-color: #FAF9F6;
}

.squares-laptop {
  display: none;
  background-color: #FAF9F6;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
.squares-mobile {
  display: none;
}

.squares-laptop {
  display: flex;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 



}
