.landing-intro-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  color: white;
  padding-left: 15px;
  height: 296px;
  position: relative;
  animation: fadeout 7s forwards;
}

.landing-intro-box {
  position: relative;
  height: 75px;
}

.fade-in-line {
  opacity: 0;
  animation: flash 1s ease-in-out 1.75s infinite;
  width: 2px;
  height: 50px;
  position: relative;
  left: 15px;
  font-size: 64px;
  background-color: white;
}

.typewriter-title-text {
  font-size: 64px;
}

@keyframes flash {
  0%, 49.9% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0%, 95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .typewriter-title-text {
    font-size: 36px;
  }
 }

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 
  .typewriter-title-text {
    font-size: 48px;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

 .typewriter-title-text {
   font-size: 64px;
 }

 .landing-intro-box {
  position: relative;
  height: 125px;
}

.fade-in-line {
  height: 100px
}

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .typewriter-title-text {
    font-size: 76px;
  }
 
  .landing-intro-box {
   position: relative;
   height: 150px;
 }
 
 .fade-in-line {
   height: 125px
 }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

  .typewriter-title-text {
    font-size: 98px;
  }
 
  .landing-intro-box {
   position: relative;
   height: 175px;
 }
 
 .fade-in-line {
   height: 150px
 }
 
}
