.full-width-banner {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.half-width-black-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: 150px;
  min-width: 100%;
  padding: 15px 0 15px 0 !important;
  background: linear-gradient(to left, transparent, rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), transparent)
}

.full-width-black-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: 150px;
  min-width: 100%;
  background: linear-gradient(to left, transparent, rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), transparent)
}

h1 {
  color: white !important;
  font-size: 48px !important;
  font-weight: 400 !important;
  font-family: 'Play', sans-serif !important;
  margin-bottom: 15px !important;
  text-align: center;
}

h2 {
  color: white !important;
  font-size: 36px !important;
  font-weight: 400 !important;
  font-family: 'Play', sans-serif !important;
  margin-bottom: 15px !important;
  text-align: center;
}

h3 {
  color: white !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  font-family: 'Play', sans-serif !important;
  margin-bottom: 15px !important;
  text-align: center;
}

h4 {
  color: white !important;
  text-align: left !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Play', sans-serif !important;
  margin-bottom: 15px !important;
}

.vertical-slide-animation {
  opacity: 0;
  transform: translateY(25px);
}

.horizontal-slide-left-animation-off {
  opacity: 0;
  transform: translateX(-25px);
}

.horizontal-slide-left-animation-on {
  opacity: 1;
}

.horizontal-slide-right-animation-off {
  opacity: 0;
  transform: translateX(25px);
}

.horizontal-slide-right-animation-on {
  opacity: 1;
}

.thin-white-bar {
  width: 150px;
  height: 2px;
  margin-bottom: 5px;
  background: linear-gradient(to right, transparent, white, transparent);
}

.thick-white-bar {
  margin: 0px auto 5px auto;
  opacity: 1;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, transparent, white);
  max-width: 200px;
}

.thick-white-bar-off {
  margin: 0px auto 5px auto;
  opacity: 0;
  width: 0%;
  height: 4px;
  background: linear-gradient(to right, transparent, white);
  max-width: 200px;
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .thin-white-bar {
    width: 300px;
  }

  .half-width-black-banner {
    min-width: 50%;
    background: linear-gradient(to left, transparent, rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), transparent)
  }

 }

 /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) { 

 }

 /* CSS to preload the large intro screen background images */

@media screen {
	div#preloader {
    position: absolute;
		left: -9999px;
		top:  -9999px;
		}
	div#preloader img {
		display: block;
		}
	}
@media print {
	div#preloader, 
	div#preloader img {
		visibility: hidden;
		display: none;
		}
	}