.header--container {
  height: 75px;
  position: fixed;
  top: 75px;
  right: 3px;
  background-color: rgb(20, 20, 20, .7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  color: white;
  font-family: "Play";
}

.header--container-mobile {
  height: 75px;
  position: relative;
  background-color: rgb(20, 20, 20, .7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  font-family: "Play";
  color: white;
}

.header--name {
  width: 100%;
  margin-left: 25px;
  min-width: 100px;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header--options-container {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.header--option-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.header--option {
  width: auto;
  margin-right: 25px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.header--option:hover {
  border-bottom: 1px solid white;
  transition: .2s;
}

.header--submenu-container {
  width: 175px;
  height: auto;
  position: absolute;
  right: 0px;
  top: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  z-index: 10;
  transition: .5s;
}

.header--submenu-option {
  width: 100%;
  padding: 15px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Play', sans-serif;
  cursor: pointer;
  background: rgb(20,20,20,.7);
  transition: .2s;
}

.header--submenu-option:hover {
  background: rgb(20,20,20,.7);
  transition: .2s;
}

.header--submenu-hamburger {
  width: 35px;
  margin-right: 15px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
  transition: .5s;
}

.header--submenu-hamburger-slice {
  width: 35px;
  height: 4px;
  border-radius: 4px;
  background-color: white;
  margin-top: 4px;
}