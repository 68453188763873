.aboutus--header {
 font-size: 36px;
 text-align: center;
 color: white;
 min-height: 150px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.aboutus--desc {
 font-size: 16px;
 color: white;
 text-align: left;
 width: 100%;
 margin-bottom: 15px;
}

.aboutus--text-container {
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-flow: column;
 padding: 15px;
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
 .aboutus--desc {
  width: 100%;
 }
}

/* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
 .aboutus--desc {
  width: 75%;
 }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
 .aboutus--desc {
  width: 50%;
 }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}