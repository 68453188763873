.landing-desc-fade {
  animation: fadeout 7s forwards;
  opacity: 0;
}

@keyframes fadeout {
  0%, 5% {
    opacity: 0;
  }
  5%, 95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}