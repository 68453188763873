.laptop-view-container {
  width: 100%;
  height: auto;
  background: linear-gradient(#1A0061, rgb(20,20,20), rgb(20,20,20));
}

.secret-image-1 {
  background-image: url('../../assets/images/lappy-1.png');
  width: 0px;
  height: 0px;
}

.secret-image-2 {
  background-image: url('../../assets/images/lappy-2.png');
  width: 0px;
  height: 0px;
}

.secret-image-3 {
  background-image: url('../../assets/images/lappy-3.png');
  width: 0px;
  height: 0px;
}

.secret-image-4 {
  background-image: url('../../assets/images/lappy-4.png');
  width: 0px;
  height: 0px;
}

.secret-image-5 {
  background-image: url('../../assets/images/lappy-5.png');
  width: 0px;
  height: 0px;
}

.laptop-1 {
  transition: .25s;
  width: 100%;
  height: 500px;
  background-image: url('../../assets/images/lappy-1.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.laptop-2 {
  transition: .25s;
  width: 100%;
  height: 500px;
  background-image: url('../../assets/images/lappy-2.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.laptop-3 {
  transition: .25s;
  width: 100%;
  height: 500px;
  background-image: url('../../assets/images/lappy-3.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.laptop-4 {
  transition: .25s;
  width: 100%;
  height: 500px;
  background-image: url('../../assets/images/lappy-4.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.laptop-5 {
  transition: .25s;
  width: 100%;
  height: 500px;
  background-image: url('../../assets/images/lappy-5.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.laptop-mobile-menu-container {
  width: 100%;
  height: 100px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop-menu-container {
  display: none;
  position: absolute;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100px;
  color: white;
  font-size: 24px;
  margin: auto;
}

.laptop-item-active {
  color: white;
  border-bottom: 1px solid white;
  padding: 0px 15px 0px 15px;
  transition: .25s;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}

.laptop-item-inactive {
  color: white;
  cursor: pointer;
  min-width: 50px;
  transition: .25s;
  padding: 0px 15px 0px 15px;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}

.laptop-tab-desc-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop-tab-desc {
  width: 100%;
  height: auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 18px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.active-ball {
  width: 50px;
  height: 50px;
  background-color: #4200FB;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 3px;
  transition: .25s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactive-ball {
  width: 50px;
  height: 50px;
  background-color: #4F4F4F;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 3px;
  transition: .25s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop-header-text {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.125rem;
  margin-bottom: 30px;
  text-align: center;
}

.laptop-call-to-action-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 30px 0;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

 }

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 
  .laptop-tab-desc {
    width: 75%;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

  .laptop-mobile-menu-container {
    display: none;
  }

.laptop-menu-container {
  display: flex;
  position: relative;
  margin: 15px 0 15px 0;
}

.laptop-item-inactive {
  min-width: 150px;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .laptop-menu-container {
    display: flex;
    justify-content: center;
    position: auto;
  }

  .laptop-tab-desc {
    width: 50%;
  }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 


 
}
