.experience--container {
  width: 100%;
  height: auto;
  background: linear-gradient(rgb(20,20,20), #282828);
  border-top: 1px solid rgb(36, 36, 36);
}

.experience--company-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 450px;
  margin-top: 30px;
  border: 1px solid rgb(36, 36, 36);
  background: rgb(0,0,0,.7);
  padding: 15px;
  -webkit-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 16px -6px rgba(0,0,0,0.75);
}

.experience--logo {
  width: 75px;
  height: 75px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid white;
  margin-bottom: 15px;
  background-position: center;
  -webkit-box-shadow: 9px 9px 20px -16px white;
  -moz-box-shadow: 9px 9px 20px -16px white;
  box-shadow: 9px 9px 20px -16px white;
}

.experience--bullet-list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  width: 100%;
  height: auto;
}

.experience--languages-container {
  width: 100%;
  margin-top: 15px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.experience--language-off {
  width: auto;
  height: auto;
  padding: 3px 9px 3px 9px;
  border-radius: 3px;
  margin: 3px 2px 3px 2px;
  opacity: 0;
}

.experience--language-on {
  width: auto;
  height: auto;
  padding: 3px 9px 3px 9px;
  border-radius: 3px;
  margin: 3px 2px 3px 2px;
  opacity: 1;
}

.experience--technologies-used {
  color: white;
}

.experience--thin-white-bar {
  width: 150px;
  height: 2px;
  margin-bottom: 5px;
  margin-top: 5px;
  background: linear-gradient(to right, transparent, white, transparent);
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .experience--company-container {
    padding: 50px;
  }

  .experience--thin-white-bar {
    width: 300px;
  }
 }