.builder-header {
  width: 100%;
  height: 75px;
  background-color: #F9F9F9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #D2D2D2;
}

.App-link {
  color: #61dafb;
}

.config-input-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}

.selected-skill-tab-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.selected-skill-tab {
  margin: 5px;
  padding: 5px 15px 5px 15px;
  border-radius: 3px;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
}

.menu-bar-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 75px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
  border: 1px solid #CCCCCC;
  margin: 15px 0;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  transition: .5s;
}

.menu-bar-button-container-open-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 75px;
  width: 70px;
  position: fixed;
  top: 90px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
  border: 1px solid #CCCCCC;
  margin: 15px 0;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  transition: .5s;
}

.menu-bar-button-container-active {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 75px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgb(20, 20, 20);
  border: 1px solid #CCCCCC;
  margin: 15px 0;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 25px -25px rgba(0,0,0,0.75);
  transition: .5s;
}

.menu-bar-button-container:hover {
  background-color: #F8F8F8;
  transition: .5s;
}

.mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockage-1 {
  width: 100%;
  background-color: #303030;
  height: 100%;
  z-index: 1;
  position: relative;
  right: 12px;
}

.blockage-2 {
  width: 100%;
  background-color: #303030;
  height: 100%;
  z-index: -1;
  position: relative;
  left: 12px;
}

.toggle-button-container {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}

.nothing {
  border: 10px solid #F9F9F9;
  color: rgb(176, 176, 176);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
}

.about-me-skills-gradient {
  background: linear-gradient(transparent, red);
}

@keyframes hover-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5; 
  }
}

.selected-skill-tab:hover {
  animation: hover-animation 0.5s forwards; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.hide-on-1024 {
  display: auto;
}

.hide-on-1100 {
  display: auto;
}

.show-on-1024 {
  display: none;
}

.show-on-700 {
  display: flex;
}

/* 700 pixels */
@media (min-width: 700px) { 
 
  .show-on-700 {
    display: none !important;
  }

 }

/* 700 pixels */
@media (max-width: 700px) { 
  .show-on-1024 {
    display: none !important;
  }



 }

 /* 1100 pixels */
@media (max-width: 1100px) { 
  .hide-on-1100 {
    display: none !important;
  }



 }
 

/* 1024 pixels */
@media (max-width: 1024px) { 
  .hide-on-1024 {
    display: none;
  }

  .show-on-1024 {
    display: flex;
  }
 }
 