.preview-header-holder {
 width: 100%;
 height: 75px;
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 flex-flow: column;
}

.preview-header-container {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 75px;
 position: fixed;
 background-color: rgb(20,20,20);
 color: white;
 z-index: 1000;
}

.preview-header-menu-container {
 display: flex;
 justify-content: flex-end;
 align-items: center;
 width: 100%;
}

.preview-header-menu-item {
 min-width: 100px;
 margin-right: 15px;
 height: 75px;
 display: none;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 border-bottom: 2px solid transparent; /* Initially transparent border */
 transition: border-bottom-color 0.3s ease; /* Smooth transition effect */
}

.preview-header-menu-item-you-are-currently {
 min-width: 100px;
 margin-right: 15px;
 height: 75px;
 z-index: 1001;
 display: none;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 border-bottom: 2px solid transparent; /* Initially transparent border */
 transition: border-bottom-color 0.3s ease; /* Smooth transition effect */
}

.landing-mobile-menu-container {
 display: flex;
 width: 50%;
 justify-content: flex-end;
 align-items: center;
}

.landing-mobile-menu-burger-container {
 display: flex;
 width: 100px;
 height: 75px;
 justify-content: space-around;
 align-items: center;
 flex-flow: column;
 padding-top: 25px;
 padding-bottom: 25px;
 cursor: pointer;
}

.landing-mobile-menu-burger-slice {
 display: flex;
 width: 45px;
 height: 3px;
 background-color: white;
 border-radius: 3px;
}

.landing-menu-item:hover {
 border-bottom-color: white; /* White border on hover */
}

.landing-mobile-menu {
 position: fixed;
 right: 0%;
 top: 75px;
 width: 50%;
 height: 100%;
}

.landing-mobile-menu-option {
 width: 100%;
 height: 45px;
 background-color: rgb(20,20,20,.5);
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
}

.preview-header-mobile-menu {
  position: fixed;
  right: 0%;
  top: 75px;
  width: 50%;
  height: 100%;
  padding: 30px 0 30px 0;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
 .landing-menu-container {
   display: flex;
 }

 .landing-mobile-menu-burger-container {
   display: none;
 }

 .landing-mobile-menu {
   display: none;
 }

 .preview-header-menu-item {
  display: flex;
 }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 .preview-header-menu-item-you-are-currently {
  display: flex;
 }

 .preview-header-you-are-currently-mobile {
  display: none;
 }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
