.about-us-page--container {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100%;
 min-height: 100vh;
 flex-flow: column;
}

.about-us-page--box {
 border: 1px solid white;
 margin: 150px 0 150px 0;
}

.about-us-page--bubble-container {
 width: 100%;
 height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 padding-bottom: 30px;
}

.about-us-page--bubble {
 width: 200px;
 height: 200px;
 border-radius: 50%;
 border: 3px solid white;
 background-image: url('../../assets/images/logan.jpg');
 background-size: cover;
 background-position: center;
 -webkit-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
 -moz-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
 box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);}