/* CSS properties to set the size of the Devicon skill images in the "Skills" section */

.devicon-git-plain {
  width: 50px;
  height: 50px;
  margin: auto;
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .devicon-git-plain {
    width: 50px;
    height: 50px;
  }
 }

 /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) { 
  .devicon-git-plain {
    width: 100px;
    height: 100px;
  }
 }