/* Custom Bootstrap styling */ 

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

a {
  color: inherit;
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
}

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}