.experience-control-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-experience {
  cursor: pointer;
}

.rearrange-experience-left {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
  border-radius: 50%;
  margin: auto;
  transition: .5s;
  cursor: pointer;
  border: 1px solid #808080;
}

.rearrange-experience-left:hover {
  background-color: rgb(20,20,20);
}

.rearrange-experience-right {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
  border-radius: 50%;
  margin: auto;
  transition: .5s;
  cursor: pointer;
  border: 1px solid #808080;
}

.rearrange-experience-right:hover {
  background-color: rgb(20,20,20);
}

.experience-arrow-containers {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-flex-end {
  display: flex;
  justify-content: flex-end;
}

.experience--header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.experience-move-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}