.skills--container {
  width: 100%;
  top: 800px;
  height: auto;
}

.skills--block-container {
  width: 100%;
  height: auto;;
  color: white;
  font-family: 'Play', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  font-size: 20px;
  flex-wrap: wrap;
}

.skills--skills-box {
  height: 50px;
  margin-bottom: 5px;
  padding-left: 5px;
  color: white;
  font-family: 'Play', sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
}

.skills--skill-tab {
  width: 4px;
  height: 100%;
  margin-right: 2px;
}

.skills--skill-tab-2 {
  width: 1px;
  height: 100%;
  margin-right: 2px;
}

.skills--skill-block {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.skills--skill-avatar {
  border-radius: 50%;
  border: 2px solid white;
  width: 100px;
  height: 100px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  -webkit-box-shadow: 9px 9px 20px -16px white;
  -moz-box-shadow: 9px 9px 20px -16px white;
  box-shadow: 9px 9px 20px -16px white;
}

.skills--skill-flag {
  width: 100%;
  height: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.skills--skill-block-title {
  width: auto;
  height: 36px;
  max-height: 36px;
  text-align: center;
  font-size: 20px;
  font-family: 'Play', sans-serif;
  color: white;
}

.skills--skill-img {
  width: 100px;
  height: 100px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid white;
  border-radius: 50%;
  -webkit-box-shadow: 9px 9px 20px -16px white;
  -moz-box-shadow: 9px 9px 20px -16px white;
  box-shadow: 9px 9px 20px -16px white;
}

.skills--animate-point {
  position: relative;
  top: -600px;
}

.skills--scroll-to-point {
  position: relative;
  bottom: 100px;
}

@media (min-width: 576px) { 

  .skills--block-container {
    top: 0px;
  }

  .skills--skill-block-title {
    font-size: 24px;
  }

@media (min-width: 768px) { 

  .skills--skill-avatar {
    width: 150px;
    height: 150px;
  }

  .skills--skill-img {
    width: 150px;
    height: 150px;
    background-size: 100px 100px;
  }

 }

@media (min-width: 992px) { 

 }

@media (min-width: 1200px) { 

 }
}

.full-width-black-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: 150px;
  min-width: 100%;
  background: linear-gradient(to left, transparent, rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), rgb(20, 20, 20, .7), transparent)
}