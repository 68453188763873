.contact--color-container {
 width: 100%;
 display: flex;
 justify-content: flex-end;
 align-items: center;
 height: auto;
 position: absolute;
 z-index: 502;
 top: 185px;
 right: 25px;
 animation: color-fade .5s forwards;
}