.steps-container {
  width: 100%;
  height: auto;
  background: linear-gradient(rgb(40, 0, 158), rgb(25, 0, 99));
}

.steps-box-1 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: flex-start;
  font-size: 36px;
  color: white;
}

.steps-box-1-mobile {
  width: 100%;
  height: auto;
  font-size: 36px;
  color: white;
  text-align: center;
  margin-top: 30px;
}

.steps-box-2 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  font-size: 18px;
  color: white;
}

.white-ball {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 3px solid white;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 15px 0;
}

.step-row {
  width: 100%;
  display: flex;
  justify-items: flex-start;
  align-items: center;
}

.steps-border {
  width: 100%;
  margin: auto;
  height: 1px;
  background-color: white;
}

.steps-mobile {
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.steps-laptop {
  display: flex;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) { 

}

.steps-mobile {
  display: flex;
}

.steps-laptop {
  display: none;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
.steps-mobile {
  display: none;
}

.steps-laptop {
  display: flex;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 



}
