.intro--margin {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.intro--container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.intro--profile-bubble {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 60px;
  background: rgb(20,20,20,.7);
  background-size: cover;
  background-position: center;
  border: 5px solid white;
  z-index: 1;
  -webkit-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
}

.intro--header {
  color: white;
  width: auto;
  height: auto;
  text-align: center;
  font-size: 36px;
  font-family: "Play";
  letter-spacing: 0px;
  margin: 15px 0px 15px 0px;
}

.intro--header-vert {
  color: white;
  width: auto;
  height: auto;
  text-align: center;
  font-size: 36px;
  font-family: "Play";
  letter-spacing: 0px;
  margin: 15px 0px 15px 0px;
}

.intro--subheader {
  color: white;
  width: auto;
  height: auto;
  font-size: 16px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Play";
  flex-flow: column;
}

.intro--subheader-vert {
  color: white;
  width: auto;
  height: auto;
  font-size: 16px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Play";
  flex-flow: column;
}

.intro--position-1 {
  animation: intro--position-left 1s 1s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0%;
  margin-top: 5px;
}

.intro--position-2 {
  animation: intro--position-bottom 1s 1.25s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0%;
  margin-top: 5px;
}

.intro--position-3 {
  animation: intro--position-right 1s 1.5s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0%;
  margin-top: 5px;
}

.intro--position-1-vert {
  animation: intro--position-left 1s 1s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0%;
  margin-top: 5px;
}

.intro--position-2-vert {
  animation: intro--position-bottom 1s 1.25s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0%;
  margin-top: 5px;
}

.intro--position-3-vert {
  animation: intro--position-right 1s 1.5s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0%;
  margin-top: 5px;
}

.intro--divider {
  width: 1px;
  height: 1px;
  opacity: 0%;
  background-color: white;
  margin: 5px 0px 5px 0px;
  animation: intro--divider-horizontal 1s .5s forwards;
}


.intro--divider-vert {
  width: 1px;
  height: 1px;
  opacity: 0%;
  background-color: white;
  margin: 5px 0px 5px 0px;
  animation: intro--divider-horizontal 1s .5s forwards;
}


.john-background-image-1 {
  background-image: url('../../assets/images/john-background-1.png');
}

.john-background-image-2 {
  background-image: url('../../assets/images/john-background-2.jpg');
}

.john-background-image-3 {
  background-image: url('../../assets/images/john-background-3.jpg');
}

.jessica-background-image-1 {
  background-image: url('../../assets/images/jessica-background-1.png');
}

.jessica-background-image-2 {
  background-image: url('../../assets/images/jessica-background-2.png');
}

.jessica-background-image-3 {
  background-image: url('../../assets/images/jessica-background-3.png');
}


.john-avatar {
  background-image: url('../../assets/images/john.JPG');
}

.jessica-avatar {
  background-image: url('../../assets/images/jessica.JPG');
}
/* Keyframe animations */

@keyframes intro--fade {
  0% {opacity: 100%}
  100% {opacity: 0%}
}

@keyframes intro--background {
  0% {transform: scale3d(1.4, 1.4, 1.4) translate3d(0px, 10px, 0px); opacity: 0;}
  15% {opacity: 1;}
  100% {transform: scale3d(1.3, 1.3, 1.3) translate3d(0px, 5px, 0px); opacity: 1;}
}

@keyframes intro--background-mobile {
  0% {background-color: rgb(24, 24, 24);}
  100% {background-color: rgb(1, 0, 56);}
}

@keyframes intro--position-left {
  0% {transform: translateX(-25px); opacity: 0%;}
  100% {transform: translateX(0px); opacity: 100%;}
}

@keyframes intro--position-bottom {
  0% {transform: translateY(25px); opacity: 0%;}
  100% {transform: translateX(0px); opacity: 100%;}
}

@keyframes intro--position-right {
  0% {transform: translateX(25px); opacity: 0%;}
  100% {transform: translateX(0px); opacity: 100%;}
}

@keyframes intro--divider-vertical {
  0% {}
  100% {opacity: 100%; height: 30px;}
}

@keyframes intro--divider-horizontal {
  0% {}
  100% {opacity: 100%; width: 30px;}
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .intro--header {
    font-size: 48px;
    letter-spacing: 5px;
  }
  .intro--subheader {
    justify-content: center;
    font-size: 24px;
    height: auto;
    flex-flow: row;
  }
  .intro--position-1 {
    padding-right: 0px;
    animation: intro--position-left 1s 1s forwards;
  }
  .intro--position-2 {
    animation: intro--position-right 1s 1.25s forwards;
  }
  .intro--position-3 {
    padding-left: 0px;
    animation: intro--position-left 1s 1.5s forwards;
  }
  .intro--divider {
    animation: intro--divider-vertical 1s .5s forwards;
    margin: 0px 25px 0px 25px;
  }
 }

 /* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .intro--profile-bubble {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
  }
  .intro--name-banner {
    bottom: 150px;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .intro--profile-bubble {
    width: 200px;
    height: 200px;
  }
  .intro--name-banner {
    bottom: 150px;
  }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .intro--name-banner {
    bottom: 200px;
  }
 }