.download-button-container {
 margin: 15px 0 15px 0;
 display: flex;
 justify-content: center;
 width: auto;
 align-items: center;
}

.input-flash {
 opacity: 0;
 animation: flashing .3s forwards;
}

.typewriter-title-text {
 font-size: 64px;
}

@keyframes flashing {
 0% {
   opacity: 0;
 }
 100% {
  opacity: 1;
}
}