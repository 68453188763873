.education--container {
  background-size: cover;
  background-position: top;
  width: 100vw;
  height: auto;
  border-top: 1px solid rgb(36, 36, 36);
}

.education--box {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.education--school-logo {
  width: 200px !important;
  height: 200px !important;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 2px solid white;
  background-size: cover;
  background-position: center;
  margin-top: 15px;
  -webkit-box-shadow: 9px 9px 20px -16px white;
  -moz-box-shadow: 9px 9px 20px -16px white;
  box-shadow: 9px 9px 20px -16px white;
}

.education--school-desc {
  width: 65%;
  text-align: center;
}

.education--button {
  width: 200px;
  height: 45px;
  background-color: #2247D2;
  border-radius: 3px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-family: 'Play', sans-serif;
  cursor: pointer;
  text-align: center;
  -webkit-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  box-shadow: 9px 9px 20px -16px rgba(0,0,0,0.75);
  transition: .2s;
}

.education--button:hover {
  background-color: rgb(53, 95, 248);
  transition: .2s;
}

.education--scroll-to-point {
  position: relative;
  bottom: 100px;
}

.devhorizons-university-logo {
  background-image: url('../../assets/images/devhorizons-university-logo.png');
}

.san-diego {
  background-image: url('../../assets/images/san-diego.jpg');
}

.monument-valley {
  background-image: url('../../assets/images/monument-valley.jpg');
}

/* Media queries for responsive design */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .education--container {
    height: 800px;
  }
 }

 /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) { 
  .education--container {
    height: 1000px;
  }
 }